<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="handleForm()" ref="myForm">
        <b-row>
          <!-- old password -->
          <b-col md="12">
            <h3 style="font-weight:600;font-size:18px;font-family: 'Public Sans', sans-serif;">Change Password</h3>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" rules="required" name="Current Password">
              <b-form-group>
                <label for="current-password" class="label"> Current Password</label>
                <b-input-group class="input-group-merge">
                  <b-form-input id="current-password" v-model="passwordValueOld" name="old-password"
                    :type="passwordFieldTypeOld" placeholder="Current Password" @input="handlePasswordChange" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIconOld" @click="togglePasswordOld" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider #default="{ errors }" rules="required" name="New Password">
              <b-form-group>
                <label for="account-new-password" class="label">New Password</label>
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew"
                    name="new-password" placeholder="New Password" />

                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIconNew" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->
          <!-- retype password -->
          <b-col md="6">
            <validation-provider #default="{ errors }" rules="required" name="Confirm New Password">
              <b-form-group>
                <label for="account-retype-new-password">Confirm New Password</label>
                <b-input-group class="input-group-merge">
                  <b-form-input id="account-retype-new-password" v-model="RetypePassword" :type="passwordFieldTypeRetype"
                    name="retype-password" placeholder="Confirm New Password" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIconRetype" @click="togglePasswordRetype" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ retype password -->
          <b-col md="12">
            <h4 style="font-weight:600;font-size:15px;font-family: 'Public Sans', sans-serif;">Password Requirements</h4>
            <ul class="ul">
              <li :class="{ 'text-success': isPasswordLengthValid, '': !isPasswordLengthValid }">
                Minimum 8 Characters Long, the more, the better
              </li>
              <li :class="{ 'text-success': isLowerCaseValid, '': !isLowerCaseValid }">
                At least one lowercase character
              </li>
              <li :class="{ 'text-success': isSpecialCharacterValid, '': !isSpecialCharacterValid }">
                At least one number, one symbol.
              </li>
            </ul>
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button style="font-family:Public Sans;" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
              class="mt-1 mr-1" @click="validatePasswordFields">
              Save changes
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1"
              style="font-family:Public Sans;">
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from '@/component/axios';;
import {
  required,
} from "@validations";


export default {
  components: {
    BButton,
    ValidationProvider, ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      required,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      isPasswordLengthValid: false,
      isLowerCaseValid: false,
      isSpecialCharacterValid: false,
    }
  },
  computed: {
    isPasswordValid() {
      return (
        this.isPasswordLengthValid &&
        this.isLowerCaseValid &&
        this.isSpecialCharacterValid
      );
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validatePasswordFields() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.handleForm();
          this.$refs.myForm.reset();
        }
      })
    },
    handleForm(e) {
      const customerData = {
        current_password: this.passwordValueOld,
        new_password: this.newPasswordValue,
        confirm_password: this.RetypePassword
      };
      const requestOptions = {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}auth/admin/change-password`,
        data: JSON.stringify(customerData),
      };
      axios(requestOptions)
        .then((response) => {
          // console.log(response)
          this.$swal({
            title: "Submitted",
            text: response.data.message
              ? `${response.data.message}`
              : response.data.success
                ? `${response.data.success}`
                : "Password Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$forceUpdate();
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.$swal({
              title: "Error!",
              text: error.response.data.message, 
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
    },
    validatePasswordRequirements() {
      this.isPasswordLengthValid = this.passwordValueOld.length >= 8;
      this.isLowerCaseValid = /[a-z]/.test(this.passwordValueOld);
      this.isSpecialCharacterValid = /[0-9!@#$%^&*(),.?":{}|<>]/.test(this.passwordValueOld);
    },
    handlePasswordChange() {
      this.validatePasswordRequirements();
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style>
.label {
  font-weight: 400;
  font-size: 13px;
  font-family: 'Public Sans', sans-serif;
}

.ul {
  font-weight: 400;
  font-size: 15px;
  font-family: 'Public Sans', sans-serif;
}
</style>